var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "TaxDrawer" },
    [
      _c(
        "Drawer",
        {
          attrs: {
            visible: _vm.accountVisible,
            size: "small",
            closable: "",
            "z-index": 100,
            "is-mask": false,
            title: _vm.drawerTypeStr === "edit" ? "编辑" : "新增" + "报税主体",
          },
          on: { close: _vm.cancelDrawer, onSubmit: _vm.onSave },
        },
        [
          _vm.destroyElement
            ? _c(
                "div",
                { staticClass: "card-content" },
                [
                  _c(
                    "a-form-model",
                    {
                      ref: "taxForm",
                      attrs: {
                        model: _vm.taxForm,
                        rules: _vm.rules,
                        "label-col": _vm.labelCol,
                        "wrapper-col": _vm.wrapperCol,
                      },
                    },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "企业名称", prop: "companyName" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入企业名称" },
                            model: {
                              value: _vm.taxForm.companyName,
                              callback: function ($$v) {
                                _vm.$set(_vm.taxForm, "companyName", $$v)
                              },
                              expression: "taxForm.companyName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "税号", prop: "taxNum" } },
                        [
                          _c("a-input", {
                            attrs: {
                              disabled:
                                _vm.drawerTypeStr === "edit" ? true : false,
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.taxForm.taxNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.taxForm, "taxNum", $$v)
                              },
                              expression: "taxForm.taxNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "所属地区", prop: "areaId" } },
                        [
                          _c("SelectArea", {
                            ref: "selectArea",
                            on: { getStrandValue: _vm.getStrandValue },
                          }),
                        ],
                        1
                      ),
                      _vm.taxForm.pwdType === "手机短信验证码"
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: { label: "手机验证码", prop: "telephone" },
                            },
                            [
                              _c("a-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.taxForm.telephone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.taxForm, "telephone", $$v)
                                  },
                                  expression: "taxForm.telephone",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "报税周期",
                            prop: "taxFilingPeriodType",
                          },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.taxForm.taxFilingPeriodType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.taxForm,
                                    "taxFilingPeriodType",
                                    $$v
                                  )
                                },
                                expression: "taxForm.taxFilingPeriodType",
                              },
                            },
                            _vm._l(_vm.taxCycleList, function (item) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.codeKey,
                                  attrs: { value: item.codeKey },
                                },
                                [_vm._v(_vm._s(item.content))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "报税人员", prop: "person" } },
                        [
                          _c("SelectPerson", {
                            attrs: {
                              "query-leave-person": true,
                              "data-echo":
                                _vm.drawerTypeStr === "add"
                                  ? _vm.taxForm.onJobIdList
                                  : _vm.spareArr,
                              placeholder: "请输入部门、人员、工号",
                            },
                            on: { perModelConfirm: _vm.perModelConfirm },
                            model: {
                              value: _vm.model,
                              callback: function ($$v) {
                                _vm.model = $$v
                              },
                              expression: "model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }